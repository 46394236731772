import React, { useState } from 'react';
import './FileView.css';

const FileView = ({ files = [], }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleDelete = (index) => {
    if (files.length <= 1) return; 
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setActiveTab(activeTab > 0 ? activeTab - 1 : 0);
  };
  console.log(files);
  
  

  return (
    <div className="tabs-container">
      <div className="tabs-list">
        {files?.map((file, index) => (
          <div
            key={index}
            className={`tab-item ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)} // Update active tab on file name click
          >
            {file.filename || file.fileName}
            <button
              className="delete-button"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(index);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        ))}
      </div>
      {files?.length > 0 && (
        <div className="tab-content">
          <h2>{files[activeTab]?.fileName || files[activeTab]?.filename}</h2>
          <p>{(files[activeTab]?.content?.substring(0, 1000)) || 'No Content Available'}</p>
        </div>
      )}
    </div>
  );
};

export default FileView;
