import React, { useState } from 'react';
import './TabPanel.css';
import { callApi } from '../../utils/api';

const TabPanel = () => {
    const [activeTab, setActiveTab] = useState('Google');
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            setSelectedFile(file);
        } else {
            setFileName('');
            setSelectedFile(null);
        }
    };

    const handleBrowseClick = () => {
        document.getElementById('fileInput').click();
    };

    // const handleUploadClick = async () => {
    //     if (!selectedFile) {
    //         alert('Please select a file first.');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('file', selectedFile);

    //     const accessToken = localStorage.getItem('access_token'); 

    //     try {
    //         const response = await fetch('https://llmdemoapp.articence.com/upload', {
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': `Bearer ${accessToken}`, // Include the token in the headers
    //             },
    //             body: formData,
    //         });

    //         if (response.ok) {
    //             alert('File uploaded successfully!');
    //         } else {
    //             console.error('Upload failed:', response.statusText);
    //             alert('Failed to upload the file.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         alert('An error occurred during file upload.');
    //     }
    // };

    const handleUploadClick = async () => {
        if (!selectedFile) {
            alert('Please select a file first.');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        try {
            await callApi('/upload', 'POST', formData, true);
            alert('File uploaded successfully!');
        } catch (error) {
            alert('An error occurred during file upload.');
        }
    };

    return (
        <div className="upload-container">
            {/* <div className="tabs">
                <button
                    className={`tab ${activeTab === 'Google' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Google')}
                >
                    Google
                </button>
                <button
                    className={`tab ${activeTab === 'SharePoint' ? 'active' : ''}`}
                    onClick={() => handleTabClick('SharePoint')}
                >
                    SharePoint
                </button>
                <button
                    className={`tab ${activeTab === 'Disabled' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Disabled')}
                    disabled
                >
                    Disabled
                </button>
            </div> */}
            <div className="content">
                <p className='content-text'>With supporting text below as a natural lead-in to additional content.</p>
                <div className="file-upload">
                    <button className="upload-btn" onClick={handleUploadClick}>
                        Upload
                    </button>
                    
                    <input 
                        type="text" 
                        className="file-input-display" 
                        value={fileName} 
                        placeholder="Choose file" 
                        readOnly 
                    />
                    
                    <input 
                        type="file" 
                        id="fileInput" 
                        className="file-input" 
                        onChange={handleFileChange} 
                        style={{ display: 'none' }} 
                    />
                    
                    <button className="browse-btn" onClick={handleBrowseClick}>
                        Browse
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TabPanel;
