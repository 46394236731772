import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import FilePage from '../landing/File/FilePage';
import HomePage from '../landing/Home/HomePage';
import SetupPage from '../landing/Setup/SetupPage';
import QueryPage from '../landing/Query/QueryPage';

const SESSION_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

function LandingPage() {
  const navigate = useNavigate();

  // Function to check session validity
  const checkSessionValidity = () => {
    const loginTime = localStorage.getItem('login_time');
    if (loginTime) {
      const currentTime = Date.now();
      const timeElapsed = currentTime - loginTime;

      if (timeElapsed > SESSION_DURATION) {
        // Session has expired after 30 minutes
        localStorage.removeItem('access_token');
        localStorage.removeItem('login_time');
        navigate('/login'); 
      }
    }
  };

  // Function to log out the user after session expires
  const setSessionTimeout = () => {
    setTimeout(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('login_time');
      navigate('/login'); // Redirect to login page after timeout
    }, SESSION_DURATION);
  };

  // Check session validity when the component mounts
  useEffect(() => {
    checkSessionValidity();
    setSessionTimeout(); // Set session expiry timer for 30 minutes
  }, []);

  return (
    <div>
      <Header />
      <div className="content">
        <Routes>
          <Route path="landing/file" element={<FilePage />} />
          {/* <Route path="landing/home" element={<HomePage />} /> */}
          <Route path="landing/home" element={<SetupPage />} />
          <Route path="landing/query" element={<QueryPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default LandingPage;
