import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  return (
    <header className="header">
      <div className="subHeader">
        <div className="logo">Articence Inc</div>
        <nav className="nav">
          <Link to="landing/file">Enterprise Setup</Link>
          {/* <Link to="landing/home">Home</Link> */}
          <Link to="landing/home">Home</Link>
          <Link to="landing/query">Query</Link>
        </nav>
      </div>
      <div className="search">
        <input type="text" placeholder="Search" className="searchInput"/>
        <button className="searchButton">Search</button>
      </div>
    </header>
  );
}

export default Header;
