import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../pages/Login/Login.scss';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleReset = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords don't match");
      return;
    }

    try {
      const response = await fetch('http://178.63.87.39:8008/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword }),
      });

      if (response.ok) {
        navigate('/login');
      } else {
        console.log('Password reset failed:', response.statusText);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div className="login-container">  {/* Reuse the class */}
      <h1>Acme Inc.</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="login">  {/* Reuse the class */}
          <label htmlFor="new-password">Enter new password:</label>
          <input
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            placeholder='New Password'
          />
          <label htmlFor="confirm-password">Confirm new password:</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder='Confirm Password'
          />
          <button onClick={handleReset}>Reset Password</button>
        </div>
        <div className="divider" />
      </form>
    </div>
  );
}

export default ResetPassword;
