import React from 'react';
import './Sidebar.css';

const Sidebar = ({ chatHistory, onQuestionClick }) => {
    return (
        <div className="sidebar">
            <h2>Query History</h2>
            <ul>
                {chatHistory.map((item, index) => (
                    <li 
                        key={index} 
                        onClick={() => onQuestionClick(item.question)}
                        title={item.question} 
                    >
                        {item.question.substring(0, 20)}...
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
