import React from 'react';

function Seperator() {
  return (
    <div>
      <hr style={{ border: '3px solid #9196cf', margin: '50px 69px' }} />
    </div>
  );
}

export default Seperator;