import React, { useState } from 'react';
import FileView from '../FileView/FileView';
import './DemoPanel.css';
import Seperator from '../Seperator/Seperator';

const imageFiles = {
  jira: {
    files: [
    {
      "fileName": "Timing_Bug_Reports.pdf",
      "content": "This PDF contains reports on timing-related bugs encountered during digital design. It includes examples of timing violations, such as setup and hold time violations, and strategies for troubleshooting and fixing these issues."
    },
    {
      "fileName": "Synthesis_Bug_Notes.pdf",
      "content": "This document outlines common bugs and issues encountered during synthesis, such as optimization failures, incorrect mappings, and constraint conflicts. It includes case studies and solutions to these synthesis-related problems."
    },
    {
      "fileName": "RTL_Simulation_Issues.pdf",
      "content": "This PDF details common issues found during RTL simulation, including problems like mismatched testbench and design interface, incomplete test coverage, and incorrect simulation results. It provides tips for debugging and resolving these issues."
    },
    {
      "fileName": "Verification_Bugs.pdf",
      "content": "This document describes bugs related to verification processes, such as assertion failures, coverage gaps, and incorrect verification results. It includes examples of common verification bugs and their solutions."
    },
    {
      "fileName": "Clock_Design_Flaws.pdf",
      "content": "This PDF highlights common bugs in clock design, including issues like clock domain crossings, skew problems, and improper clock gating. It provides insights into diagnosing and fixing these clock-related problems."
    },
    {
      "fileName": "Power_Analysis_Bugs.pdf",
      "content": "This document discusses issues encountered during power analysis, such as incorrect power estimates, unexpected power consumption, and problems with power intent files. It includes methods for debugging and correcting these issues."
    },
    {
      "fileName": "Physical_Design_Errors.pdf",
      "content": "This PDF covers common bugs found in physical design, such as routing issues, DRC (Design Rule Check) violations, and incorrect placement. It includes examples and troubleshooting steps for these physical design errors."
    },
    {
      "fileName": "Signal_Integrity_Problems.pdf",
      "content": "This document describes signal integrity issues, including problems like crosstalk, noise, and signal degradation. It includes examples of signal integrity bugs and strategies for resolving them."
    },
    {
      "fileName": "Integration_Bugs.pdf",
      "content": "This PDF contains information on bugs found during the integration phase, such as interface mismatches, integration failures, and compatibility issues between different blocks of the design. It includes troubleshooting tips and solutions."
    },
    {
      "fileName": "Documentation_Errors.pdf",
      "content": "This document outlines common errors found in design documentation, such as discrepancies between documentation and actual design, incomplete or unclear documentation, and incorrect design descriptions. It provides methods for correcting these errors."
    },
    {
      "fileName": "bug_tracking_system.bib",
      "content": "@book{bugs1,\n    author = {Chris Adams},\n    title = {Debugging Digital Designs: A Practical Guide},\n    publisher = {Wiley},\n    year = {2021}\n}\n\n@article{bugs2,\n    author = {Sandra Lee and Robert Miller},\n    title = {Addressing Common Bugs in Digital Design Flows},\n    journal = {IEEE Transactions on Computer-Aided Design},\n    year = {2023},\n    volume = {42},\n    number = {5},\n    pages = {875-885}\n}"
    }
  ]
  },

  googledrive: {
    files: [
      {
        "fileName": "DFT_Theory_Overview.pdf",
        "content": "This PDF file contains an overview of Density Functional Theory (DFT), including its basic principles, history, and applications. It covers topics such as exchange-correlation functionals, the Kohn-Sham equations, and practical aspects of DFT calculations."
      },
      {
        "fileName": "DFT_Example_Calculation.pdf",
        "content": "This PDF file provides a step-by-step example of a DFT calculation, including input file setup, parameter choices, and interpretation of results. It includes sample outputs and explanations for a typical semiconductor material."
      },
      {
        "fileName": "DFT_Benchmark_Study.ppt",
        "content": "This PDF contains a benchmark study comparing different DFT functionals and pseudopotentials for accuracy and performance. It includes tables and graphs illustrating the results of various test calculations."
      },
      {
        "fileName": "DFT_Tutorial.pdf",
        "content": "A tutorial PDF aimed at beginners, detailing how to set up and run DFT calculations using common software packages. It includes examples, tips, and troubleshooting advice."
      },
      {
        "fileName": "DFT_Convergence_Criteria.pdf",
        "content": "This document discusses convergence criteria in DFT calculations, including how to determine the appropriate cutoff energies and k-point meshes to achieve reliable results."
      },
      {
        "fileName": "input_file_template.txt",
        "content": "&CONTROL\n    calculation = 'scf'\n    prefix = 'example'\n    outdir = './tmp/'\n    pseudo_dir = './pseudopotentials/'\n/\n&SYSTEM\n    ibrav = 2\n    celldm(1) = 10.0\n    nat = 2\n    ntyp = 1\n    ecutwfc = 30.0\n    ecutrho = 300.0\n/\n&ELECTRONS\n    conv_thr = 1.0d-8\n/\nATOMIC_SPECIES\n Si  28.0855  Si.pbe-n-kjpaw_psl.0.1.UPF\nATOMIC_POSITIONS (crystal)\n Si 0.00 0.00 0.00\n Si 0.25 0.25 0.25\nK_POINTS (automatic)\n 4 4 4 0 0 0\n"
      },
      {
        "fileName": "output_file_sample.txt",
        "content": "Program PWSCF v6.7.1 starts on 2 Sep 2024 at 12:34:56\n\nInput summary:\n\nSystem parameters:\n- Lattice constant: 10.0 Bohr\n- Number of atoms: 2\n- Number of types: 1\n\nSCF convergence reached in 8 iterations.\nFinal total energy: -9.12345678 Ry\n\nFor further details, refer to the detailed output file.\n"
      },
      {
        "fileName": "DFT_References.bib",
        "content": "@article{dft1,\n    author = {John Doe and Jane Smith},\n    title = {Introduction to Density Functional Theory},\n    journal = {Journal of Computational Chemistry},\n    year = {2023},\n    volume = {44},\n    number = {3},\n    pages = {123-145}\n}\n\n@book{dft2,\n    author = {Alan Turing},\n    title = {Advanced Topics in DFT},\n    publisher = {Springer},\n    year = {2022}\n}"
      }
    ]
  },
  edm: {
    files: [
      {
        "fileName": "Project_X_Post_Mortem.ppt",
        "content": "This PDF provides a post-mortem analysis of Project X, a digital design project that focused on developing a high-performance FPGA-based system. The document covers what went well, challenges encountered, and lessons learned from the project, including detailed discussions on design decisions, debugging, and final outcomes."
      },
      {
        "fileName": "Project_Y_Review.pdf",
        "content": "This document reviews Project Y, which involved designing a custom ASIC for consumer electronics. The post-mortem analysis includes an examination of project goals, issues faced during implementation, and the effectiveness of design and verification strategies. Recommendations for future projects are also included."
      },
      {
        "fileName": "Project_Z_Lessons_Learned.pdf",
        "content": "This PDF summarizes the lessons learned from Project Z, which focused on the development of a complex digital communication system. The document highlights key successes, unexpected problems, and areas for improvement. It includes a discussion on integration challenges, performance metrics, and team management."
      },
      {
        "fileName": "Project_A_Failure_Analysis.pdf",
        "content": "This document provides an analysis of the failures encountered in Project A, a digital design project aimed at developing a high-speed data processing unit. The post-mortem includes a detailed breakdown of what went wrong, including design flaws, verification issues, and mitigation strategies that were attempted."
      },
      {
        "fileName": "Project_B_Success_Stories.ppt",
        "content": "This PDF highlights the success stories from Project B, which was focused on designing a low-power microcontroller for embedded applications. It details the successful implementation of power-saving features, efficient design practices, and how the project met or exceeded its objectives."
      },
      {
        "fileName": "Project_C_Challenge_Review.pdf",
        "content": "This document reviews the challenges faced during Project C, which involved developing a high-frequency clock generator. The post-mortem analysis includes an overview of the design challenges, testing difficulties, and how the team addressed these issues to achieve a working solution."
      },
      {
        "fileName": "Project_D_Implementation_Insights.pdf",
        "content": "This PDF provides insights into the implementation phase of Project D, which focused on a complex digital signal processing (DSP) system. The document includes an assessment of design choices, integration issues, and how the project team overcame various hurdles during implementation."
      },
      {
        "fileName": "Project_E_Timelines_and_Delays.docx",
        "content": "This document analyzes the timelines and delays experienced during Project E, a digital design project for a new generation of high-speed network processors. The post-mortem includes a detailed account of project scheduling issues, reasons for delays, and strategies employed to address them."
      },
      {
        "fileName": "Project_F_Optimization_Efforts.pdf",
        "content": "This PDF examines the optimization efforts in Project F, which aimed at designing an energy-efficient GPU. The post-mortem analysis includes a review of the optimization techniques used, challenges encountered in achieving power and performance goals, and lessons learned from the optimization process."
      },
      {
        "fileName": "Project_G_Post_Mortem_Report.docx",
        "content": "This document provides a comprehensive post-mortem report for Project G, a digital design project focused on developing a multi-core processor. The report includes an analysis of project goals, design and verification issues, team performance, and recommendations for future projects."
      },
      {
        "fileName": "project_postmortem_references.bib",
        "content": "@book{postmortem1,\n    author = {James Anderson},\n    title = {Digital Design Projects: Case Studies and Post-Mortems},\n    publisher = {Springer},\n    year = {2022}\n}\n\n@article{postmortem2,\n    author = {Linda Thompson and Brian Cook},\n    title = {Lessons Learned from Digital Design Projects},\n    journal = {IEEE Transactions on Engineering Management},\n    year = {2023},\n    volume = {70},\n    number = {4},\n    pages = {556-567}\n}"
      }
    ]
  },
  Box: {
    files: [
      {
        "fileName": "RTL_Concepts_Overview.pdf",
        "content": "This PDF file provides an overview of Register Transfer Level (RTL) design. It covers fundamental concepts, including data transfer operations, registers, and the role of RTL in digital circuit design. It also introduces key terminology and basic examples."
      },
      {
        "fileName": "RTL_Synthesis_Guide.docx",
        "content": "This guide explains the process of synthesizing RTL code into gate-level circuits. It includes information on synthesis tools, common synthesis constraints, and practical tips for optimizing RTL code for synthesis."
      },
      {
        "fileName": "RTL_Verification_Methods.ppt",
        "content": "This document discusses various methods for verifying RTL designs, including simulation, formal verification, and assertion-based verification. It includes examples and best practices for ensuring design correctness."
      },
      {
        "fileName": "RTL_Tutorial.pdf",
        "content": "A beginner's tutorial on RTL design, covering basic concepts, syntax, and coding practices in Verilog or VHDL. It includes simple examples and exercises to help new designers get started."
      },
      {
        "fileName": "RTL_Code_Template.v",
        "content": "module counter(\n    input clk,\n    input rst_n,\n    output reg [7:0] count\n);\n\n    always @(posedge clk or negedge rst_n) begin\n        if (!rst_n)\n            count <= 8'b0;\n        else\n            count <= count + 1;\n    end\nendmodule\n"
      },
      {
        "fileName": "testbench_template.v",
        "content": "module tb_counter;\n    reg clk;\n    reg rst_n;\n    wire [7:0] count;\n\n    // Instantiate the counter module\n    counter uut (\n        .clk(clk),\n        .rst_n(rst_n),\n        .count(count)\n    );\n\n    // Clock generation\n    always #5 clk = ~clk;\n\n    initial begin\n        clk = 0;\n        rst_n = 0;\n        #10 rst_n = 1;\n        #100 $finish;\n    end\nendmodule\n"
      },
      {
        "fileName": "RTL_Design_Examples.pdf",
        "content": "This PDF contains a collection of RTL design examples, including simple digital circuits such as counters, multiplexers, and state machines. Each example includes RTL code and an explanation of the design."
      },
      {
        "fileName": "RTL_References.bib",
        "content": "@book{rtl1,\n    author = {David Harris and Sarah Harris},\n    title = {Digital Design and Verilog HDL Fundamentals},\n    publisher = {Elsevier},\n    year = {2019}\n}\n\n@article{rtl2,\n    author = {John Wakerly},\n    title = {RTL Design with Verilog},\n    journal = {IEEE Transactions on Computer-Aided Design},\n    year = {2020},\n    volume = {39},\n    number = {12},\n    pages = {2345-2355}\n}"
      }
    ]
  },
  sharePoint: {
    files : [
      {
        "fileName": "Project_X_Post_Mortem.ppt",
        "content": "This PDF provides a post-mortem analysis of Project X, a digital design project that focused on developing a high-performance FPGA-based system. The document covers what went well, challenges encountered, and lessons learned from the project, including detailed discussions on design decisions, debugging, and final outcomes."
      },
      {
        "fileName": "Project_Y_Review.pdf",
        "content": "This document reviews Project Y, which involved designing a custom ASIC for consumer electronics. The post-mortem analysis includes an examination of project goals, issues faced during implementation, and the effectiveness of design and verification strategies. Recommendations for future projects are also included."
      },
      {
        "fileName": "Project_Z_Lessons_Learned.pdf",
        "content": "This PDF summarizes the lessons learned from Project Z, which focused on the development of a complex digital communication system. The document highlights key successes, unexpected problems, and areas for improvement. It includes a discussion on integration challenges, performance metrics, and team management."
      },
      {
        "fileName": "Project_A_Failure_Analysis.pdf",
        "content": "This document provides an analysis of the failures encountered in Project A, a digital design project aimed at developing a high-speed data processing unit. The post-mortem includes a detailed breakdown of what went wrong, including design flaws, verification issues, and mitigation strategies that were attempted."
      },
      {
        "fileName": "Project_B_Success_Stories.ppt",
        "content": "This PDF highlights the success stories from Project B, which was focused on designing a low-power microcontroller for embedded applications. It details the successful implementation of power-saving features, efficient design practices, and how the project met or exceeded its objectives."
      },
      {
        "fileName": "Project_C_Challenge_Review.pdf",
        "content": "This document reviews the challenges faced during Project C, which involved developing a high-frequency clock generator. The post-mortem analysis includes an overview of the design challenges, testing difficulties, and how the team addressed these issues to achieve a working solution."
      },
      {
        "fileName": "Project_D_Implementation_Insights.pdf",
        "content": "This PDF provides insights into the implementation phase of Project D, which focused on a complex digital signal processing (DSP) system. The document includes an assessment of design choices, integration issues, and how the project team overcame various hurdles during implementation."
      },
      {
        "fileName": "Project_E_Timelines_and_Delays.docx",
        "content": "This document analyzes the timelines and delays experienced during Project E, a digital design project for a new generation of high-speed network processors. The post-mortem includes a detailed account of project scheduling issues, reasons for delays, and strategies employed to address them."
      },
      {
        "fileName": "Project_F_Optimization_Efforts.pdf",
        "content": "This PDF examines the optimization efforts in Project F, which aimed at designing an energy-efficient GPU. The post-mortem analysis includes a review of the optimization techniques used, challenges encountered in achieving power and performance goals, and lessons learned from the optimization process."
      },
      {
        "fileName": "Project_G_Post_Mortem_Report.docx",
        "content": "This document provides a comprehensive post-mortem report for Project G, a digital design project focused on developing a multi-core processor. The report includes an analysis of project goals, design and verification issues, team performance, and recommendations for future projects."
      },
      {
        "fileName": "project_postmortem_references.bib",
        "content": "@book{postmortem1,\n    author = {James Anderson},\n    title = {Digital Design Projects: Case Studies and Post-Mortems},\n    publisher = {Springer},\n    year = {2022}\n}\n\n@article{postmortem2,\n    author = {Linda Thompson and Brian Cook},\n    title = {Lessons Learned from Digital Design Projects},\n    journal = {IEEE Transactions on Engineering Management},\n    year = {2023},\n    volume = {70},\n    number = {4},\n    pages = {556-567}\n}"
      }
    ]
  },
  
  
  confluence: {
    files: [
        {
          "fileName": "Synthesis_Overview.pdf",
          "content": "This PDF provides an overview of synthesis in semiconductor design. It covers fundamental concepts, including RTL synthesis, logic optimization, and the role of synthesis in converting high-level descriptions into gate-level implementations."
        },
        {
          "fileName": "Synthesis_Best_Practices.ppt",
          "content": "This document outlines best practices for synthesis in semiconductor design. It includes tips on writing synthesizable RTL code, setting synthesis constraints, and optimizing designs for performance, area, and power."
        },
        {
          "fileName": "Synthesis_Tools_Comparison.pdf",
          "content": "This PDF compares various synthesis tools available in the semiconductor industry, such as Synopsys Design Compiler, Cadence Genus, and Mentor Graphics' Precision RTL. It highlights their features, strengths, and limitations."
        },
        {
          "fileName": "Synthesis_Tutorial.pdf",
          "content": "A beginner's tutorial on synthesis tools and techniques. This document provides step-by-step instructions on setting up synthesis runs, applying constraints, and interpreting synthesis reports."
        },
        {
          "fileName": "rtl_code_for_synthesis.v",
          "content": "module adder(\n    input [3:0] a,\n    input [3:0] b,\n    output [4:0] sum\n);\n\n    assign sum = a + b;\nendmodule\n"
        },
        {
          "fileName": "synthesis_constraints.txt",
          "content": "# Synthesis Constraints\n\n# Set maximum clock period\nset_clock_period 10 [get_ports clk]\n\n# Define area constraints\nset_max_area 5000 [get_cells]\n\n# Apply timing constraints\nset_input_delay 2 [get_ports data_in]\nset_output_delay 2 [get_ports data_out]\n"
        },
        {
          "fileName": "Synthesis_Case_Studies.pdf",
          "content": "This PDF presents case studies of synthesis in real semiconductor projects. It includes examples of optimization techniques, challenges faced during synthesis, and the impact of synthesis decisions on final chip performance."
        },
        {
          "fileName": "Synthesis_References.bib",
          "content": "@book{synth1,\n    author = {R. P. G. Hartley},\n    title = {Digital Synthesis and Timing Constraints},\n    publisher = {Cambridge University Press},\n    year = {2020}\n}\n\n@article{synth2,\n    author = {Alice Walker and Brian Taylor},\n    title = {Advanced Synthesis Techniques for High-Performance Designs},\n    journal = {IEEE Transactions on VLSI Systems},\n    year = {2021},\n    volume = {29},\n    number = {7},\n    pages = {1122-1133}\n}"
        }
      ]
  }
};

const images = [
  { src: "../../../images/file/jira.png", alt: "Jira", key: 'jira' },
  { src: "../../../images/file/confluence.png", alt: "Confluence", key: 'confluence' },
  { src: "../../../images/file/googledrive.jpg", alt: "googledrive", key: 'googledrive' },
  { src: "../../../images/file/sharePoint.png", alt: "sharePoint", key: 'sharePoint' },
  { src: "../../../images/file/Box.png", alt: "Box", key: 'Box' },
  { src: "../../../images/file/edm.jpg", alt: "edm", key: 'edm' },
];

const DemoPanel = () => {
  const [selectedFiles, setSelectedFiles] = useState(imageFiles.jira.files); // Default to Jira files

  const handleImageClick = (key) => {
    setSelectedFiles(imageFiles[key]?.files || []); // Set files based on clicked image key
  };
  

  return (
    <div>
    <div className='image-head'>
      <div className="image-gallery">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className="gallery-image"
            onClick={() => handleImageClick(image.key)}
          />
        ))}
      </div></div>
      <Seperator/>
      <FileView files={selectedFiles} />
    
    </div>
  );
};

export default DemoPanel;
