
export const callApi = async (endpoint, method = 'GET', body = null, isFormData = false) => {
    const baseUrl = 'https://llmdemoapp.articence.com';
    const url = `${baseUrl}${endpoint}`;
    const accessToken = localStorage.getItem('access_token');

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
    };

    if (!isFormData) {
        headers['Content-Type'] = 'application/json';
    }

    const options = {
        method,
        headers,
    };

    if (body) {
        options.body = isFormData ? body : JSON.stringify(body);
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
};
