import React from 'react'
import ChatApp from '../../../components/Query/ChatApp/ChatApp';

function QueryPage() {
    return (
        <div >
            <ChatApp/>
        </div>
    )
}

export default QueryPage