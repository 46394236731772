import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ChatWindow from '../ChatWindow/ChatWindow';
import './ChatApp.css';
import { callApi } from '../../../utils/api';

const ChatApp = () => {
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [clicked, setClicked] = useState(false);
    const [fetchTime, setFetchTime] = useState(null); // Store API fetch time

    const handleQuestionClick = (question) => {
        setSelectedQuestion(question);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSend = async () => {
        const question = inputValue;
        setClicked(true);
        setFetchTime(null); // Reset fetch time

        if (!question) return;

        const payload = { query: question };
        const startTime = Date.now(); // Capture the start time

        try {
            const data = await callApi('/chat', 'POST', payload);
            const endTime = Date.now(); // Capture the end time
            const timeTaken = (endTime - startTime) / 1000; // Calculate time in seconds

            setFetchTime(timeTaken); // Set fetch time
            const answer = data.text || 'This is a default answer.';

            setChatHistory([...chatHistory, { question, answer }]);
            setSelectedQuestion(question);
        } catch (error) {
            console.error('Failed to fetch response:', error.message);
            setChatHistory([...chatHistory, { question, answer: 'Failed to get a response from the server.' }]);
        }

        setInputValue('');
    };

    const handleTypingComplete = () => {
        setClicked(false); // Hide spinner when typing is done
    };

    return (
        <div className="chat-app">
            <Sidebar chatHistory={chatHistory} onQuestionClick={handleQuestionClick} />
            <div className="chat-area">
                <ChatWindow 
                    chatHistory={chatHistory}
                    selectedQuestion={selectedQuestion}
                    onTypingComplete={handleTypingComplete}
                />

                <div className="chat-input-container">
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Type your question here..."
                        className="chat-input"
                    />
                    {clicked ? (
                        fetchTime ? (
                            <div className="fetch-time">Time: {Math.floor(fetchTime)}s</div> // Show fetch time instead of spinner
                        ) : (
                            <div className="spinner"></div> // Show spinner while fetching
                        )
                    ) : (
                        <button
                            onClick={handleSend}
                            className="send-button"
                            disabled={!inputValue.trim()} 
                        >
                            Send
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatApp;
