import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../components/Header/Header';
import ContentSection from '../../../components/ContentSection/ContentSection';
import TabPanel from '../../../components/TabPanel/TabPanel';
import FileView from '../../../components/FileView/FileView';
import Seperator from '../../../components/Seperator/Seperator';
import './SetupPage.css'; 
import DemoPanel from '../../../components/TabPanel/DemoPanel';
import { callApi } from '../../../utils/api';

function SetupPage() {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [fileData, setFileData] = useState(null); 
  const hasFetched = useRef(false); // Create a ref to track if the API has been called

  const handleToggle = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  // useEffect(() => {
  //   // Check if the API has already been called
  //   if (hasFetched.current) return;
    
  //   const callApiOnLoad = async () => {
  //     const accessToken = localStorage.getItem('access_token'); 

  //     try {
  //       const response = await fetch('http://178.63.87.39:8008/files', {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': `Bearer ${accessToken}`, 
  //         },
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         setFileData(data);
  //         hasFetched.current = true; // Set the ref to true after successful API call
  //       } else {
  //         console.error('API call failed:', response.statusText);
  //       }
  //     } catch (error) {
  //       console.error('Error during API call:', error);
  //     }
  //   };

  //   callApiOnLoad(); 
  // }, []); // Empty dependency array ensures this effect runs only on mount

  useEffect(() => {
    if (hasFetched.current) return;

    const callApiOnLoad = async () => {
        try {
            const data = await callApi('/files');
            setFileData(data);
            hasFetched.current = true;
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    callApiOnLoad();
}, []);

  return (
    <div className="dashboard">
      {/* <Header /> */}
      <div className={'content'}>
        <ContentSection isSwitchOn={isSwitchOn} handleToggle={handleToggle} />
        <Seperator />
        <div>
          {!isSwitchOn ? <DemoPanel /> : <TabPanel />}
          <Seperator />
          {isSwitchOn ? <FileView files={fileData}/> : ''} 
        </div>
      </div>
    </div>
  );
}

export default SetupPage;
