import React from 'react';
import './ContentSection.css';

function ContentSection({ isSwitchOn, handleToggle }) {
  return (
    <div className="card-container">
      <div className="card">
        <div className="card-header">
          <h3>Enterprise Information Consolidator</h3>
          <div className="card-subheader">
            <label className="switch">
              <input type="checkbox" checked={isSwitchOn} onChange={handleToggle} />
              <span className="slider round"></span>
            </label>
            <span className="disabled-text">
              {isSwitchOn ? 'Demo Disabled' : 'Demo Enabled'}
            </span>
          </div>
        </div>
        <p className="card-text">
          Unlock insights from disparate sources with our AI-powered solution, while maintaining the highest levels of security and control. Our Large Language Model (LLM) Rapid Application Grouping (RAG) technology integrates data from Google Workspace, SharePoint, and other platforms, providing a unified view of your enterprise information.
        </p>
        <div className="divider"></div>
        <p className="card-footer">
          <div className='card-points'>
          Key Benefits:
          <br />
          <ul>
            <li>Streamline data collection</li>
            <li>Unify disparate info into a single view</li>
            <li>Gain insights through pattern recognition</li>
          </ul>
          </div>
          <div className='card-points'>
          Security and Control:
          <br />
          <ul>
            <li>Role-based access control ensures that only authorized users can access consolidated data</li>
            <li>Advanced encryption and secure authentication protocols protect sensitive information</li>
            <li>Audit logs and real-time monitoring provide transparency and trust</li>
          </ul>
          </div>
        </p>
      </div>
    </div>
  );
}

export default ContentSection;
