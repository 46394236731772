import React, { useState } from 'react';
import './FilePage.css';

const FilePage = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [processingCompleted, setProcessingCompleted] = useState(false);

    const integrations = [
        { src: "../../../images/file/googledrive.jpg", alt: "Google Drive" },
        { src: "../../../images/file/sharePoint.png", alt: "SharePoint" },
        { src: "../../../images/file/Box.png", alt: "Box" },
        { src: "../../../images/file/confluence.png", alt: "Confluence" },
        { src: "../../../images/file/jira.png", alt: "Jira" },
        { src: "../../../images/file/edm.jpg", alt: "EDM" }
    ];

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleIntegrationSelect = (integration) => {
        setSelectedIntegration(integration);
        setDropdownOpen(false);
    };

    const handleTestClick = () => {
        setProcessing(true);
        setProcessingCompleted(false);
        setTimeout(() => {
            setProcessing(false);
            setProcessingCompleted(true);
        }, 3000);
    };

    const handleClosePopup = () => {
        setProcessing(false);
        setProcessingCompleted(false);
    };

    return (
        <div className="filePage-setup-container">
            <div className='file-head'>
                <h3>Enterprise Setup</h3>
                <p className='file-p'>Used to Setup Integrations</p>
            </div>
            <div className="input-group">
                <label>Choose Inference</label>
                <select >
                    <option>Groq</option>
                    <option>Cerebras</option>
                    <option>Blaize</option>
                </select>
            </div>

            <div className="input-group">
                <label>Choose Model</label>
                <select >
                    <option>llama3</option>
                    <option>Open AI</option>
                </select>
            </div>

            <div className="input-group">
                <label>Integrations</label>
                <div className="custom-dropdown">
                    <div className="dropdown-header" onClick={handleDropdownToggle}>
                        {selectedIntegration ? (
                            <img src={selectedIntegration.src} alt={selectedIntegration.alt} />
                        ) : (
                            <span>Select Integration</span>
                        )}
                    </div>
                    {isDropdownOpen && (
                        <div className="dropdown-list">
                            {integrations.map((integration, index) => (
                                <div
                                    key={index}
                                    className="dropdown-item"
                                    onClick={() => handleIntegrationSelect(integration)}
                                >
                                    <img src={integration.src} alt={integration.alt} />
                                    <span>{integration.alt}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="integrations-section">
                <div className="integrations-icons">
                    {integrations.map((integration, index) => (
                        <img
                            key={index}
                            src={integration.src}
                            alt={integration.alt}
                            className={`integration-icon ${
                                selectedIntegration?.alt === integration.alt ? 'highlighted' : ''
                            }`}
                        />
                    ))}
                </div>

                <div className="integration-details">
                    <h3>Integrations</h3>
                    <div className="input-group">
                        <label>EndPoint</label>
                        <input type="text" placeholder="GET https://graph.microsoft.com/v1.0/me HTTP/1.1" />
                    </div>
                    <div className="input-group">
                        <label>Host</label>
                        <input type="text" placeholder="GET https://graph.microsoft.com/v1.0/me HTTP/1.1" />
                    </div>
                    <div className="input-group">
                        <label>Authorization</label>
                        <input type="text" placeholder="Authorization: Bearer eyJ0eXAiO ... 0X2tnSQL...SPhVg&Kg==" />
                    </div>
                    <div className='radio-head'>
                    <div className="radio-group">
                        <input type="radio" id="test" name="deploy" onClick={handleTestClick} />
                        <label htmlFor="test">Test</label>
                        <input type="radio" id="deploy" name="deploy" />
                        <label htmlFor="deploy">Deploy</label>
                    </div>
                    <div className="checkbox-group">
                        <input type="checkbox" id="include-emails" />
                        <label htmlFor="include-emails">Include Emails</label>
                    </div>
                    </div>
                </div>
            </div>

            {isProcessing && (
                <div className="popup">
                    <div className="popup-content">
                        <p>Processing integration...</p>
                        {processingCompleted ? (
                            <button className='button-ok' onClick={handleClosePopup}>OK</button>
                        ) : (
                            <div className="processing-bar">
                                <div className="processing-indicator"></div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <button className="save-button">Save</button>
        </div>
    );
};

export default FilePage;
