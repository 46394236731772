import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Login/Login.scss'; 
import './signup.scss';
import { callApi } from '../../utils/api';

function Signup() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationStatus, setValidationStatus] = useState({
    capitalLetter: false,
    smallLetter: false,
    specialCharacter: false,
    number: false,
    minLength: false,
  });
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const capitalLetter = /[A-Z]/.test(password);
    const smallLetter = /[a-z]/.test(password);
    const specialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const number = /[0-9]/.test(password);
    const minLength = password.length >= 7;

    setValidationStatus({
      capitalLetter,
      smallLetter,
      specialCharacter,
      number,
      minLength,
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (
  //     password === confirmPassword &&
  //     Object.values(validationStatus).every((status) => status)
  //   ) {
  //     const payload = { username, email, password };

  //     try {
  //       const response = await fetch('https://llmdemoapp.articence.com/signup', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(payload),
  //       });

  //       if (response.ok) {
  //         navigate('/login');
  //       } else {
  //         console.log('Signup failed:', response.statusText);
  //       }
  //     } catch (error) {
  //       console.log('Error:', error);
  //     }
  //   } else {
  //     console.log('Passwords do not match or validation failed');
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      password === confirmPassword &&
      Object.values(validationStatus).every((status) => status)
    ) {
        const payload = { username, email, password };

        try {
            await callApi('/signup', 'POST', payload);
            navigate('/login');
        } catch (error) {
            console.log('Signup failed:', error.message);
        }
    } else {
        console.log('Passwords do not match or validation failed');
    }
};

  return (
    <div className="login-container">
      <div className='arti'>
      <h1>Acme Inc.</h1>
      </div>
      <div>
      <form onSubmit={handleSubmit} className='sign-form'>
        <div className="login">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder="Username"
             className='input-password'
          />
          <label htmlFor="email">Email address:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="email@example.com"
             className='input-password'
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            placeholder="Password"
            className='input-password'
          />
          <div className="password-validation">
            <p
              style={{
                color: validationStatus.capitalLetter ? 'green' : 'red',
                fontSize: '12px',
                textAlign: 'left'
              }}
            >
              • At least one capital letter
            </p>
            <p
              style={{
                color: validationStatus.smallLetter ? 'green' : 'red',
                fontSize: '12px',
              }}
            >
              • At least one small letter
            </p>
            <p
              style={{
                color: validationStatus.specialCharacter ? 'green' : 'red',
                fontSize: '12px',
              }}
            >
              • At least one special character
            </p>
            <p
              style={{
                color: validationStatus.number ? 'green' : 'red',
                fontSize: '12px',
              }}
            >
              • At least one number
            </p>
            <p
              style={{
                color: validationStatus.minLength ? 'green' : 'red',
                fontSize: '12px',
              }}
            >
              • Minimum length of 7 characters
            </p>
          </div>
          <label htmlFor="confirm-password">Confirm Password:</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder="Confirm Password"
             className='confirm-password'
          />
          <button type="submit">Sign up</button>
        </div>
        <div className="divider" />
        <p className="already">
          Already have an account? <a href="/login">Sign in</a>
        </p>
      </form>
      </div>
    </div>
  );
}

export default Signup;
