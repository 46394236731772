import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../pages/Login/Login.scss';
import './OTPVerification.scss'

function OTPVerification() {
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus on the next input if current input is filled
    if (value !== '' && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleSend = async () => {
    const otpCode = otp.join('');
    try {
      const response = await fetch('http://178.63.87.39:8008/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otp: otpCode }),
      });

      if (response.ok) {
        navigate('/reset-password');
      } else {
        console.log('OTP verification failed:', response.statusText);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div className="login-container">  {/* Reuse the class */}
      <h1>Acme Inc.</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="login">  {/* Reuse the class */}
          <label>Enter verification code:</label>
          <div className="otp-inputs">
            {otp.map((value, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                maxLength="1"
                className="otp-input"
              />
            ))}
          </div>
          <button onClick={handleSend}>Send</button>
          <p><a href="#" onClick={handleSend}>Resend Email</a></p>
        </div>
        <div className="divider" />
      </form>
    </div>
  );
}

export default OTPVerification;
