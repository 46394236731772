import './App.css';
import LandingPage from './pages/landing/LandingPage';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import QueryPage from './pages/landing/Query/QueryPage';
import Login from './pages/Login/Login';
import SetupPage from './pages/landing/Setup/SetupPage';
import Signup from './pages/signup/Signup';
import ForgotPassword from './components/ForgotPassword/ForgotPassword/ForgotPassword';
import OTPVerification from './components/ForgotPassword/OTPVerification/OTPVerification';
import ResetPassword from './components/ForgotPassword/ResetPassword/ResetPassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/otp-verification" element={<OTPVerification/>} />
        <Route path="/reset-password" element={<ResetPassword/>} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/landing/*" element={<LandingPage/>} />
      </Routes>
    </Router>
  );
}

export default App;