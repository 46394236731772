import React, { useState, useEffect } from 'react';
import './ChatWindow.css';

const ChatWindow = ({ chatHistory, selectedQuestion, onTypingComplete }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [hasTypedBefore, setHasTypedBefore] = useState({});

    const selectedChat = chatHistory.find(item => item.question === selectedQuestion);
    const typingSpeed = 30;

    useEffect(() => {
        if (!selectedChat || !selectedChat.answer) return;

        // Check if the selected chat answer has already been typed
        if (hasTypedBefore[selectedQuestion]) {
            setDisplayedText(selectedChat.answer); // Display full answer without typing
            return;
        }

        // First time typing for this question
        setDisplayedText('');
        let currentIndex = 0;
        const intervalId = setInterval(() => {
            if (currentIndex < selectedChat.answer.length) {
                const nextChar = selectedChat.answer[currentIndex];
                setDisplayedText(prev => prev + nextChar);
                currentIndex++;
            } else {
                clearInterval(intervalId);
                setHasTypedBefore(prev => ({ ...prev, [selectedQuestion]: true })); // Mark as typed
                onTypingComplete(); // Notify that typing is complete
            }
        }, typingSpeed);

        return () => clearInterval(intervalId);
    }, [selectedChat, selectedQuestion, hasTypedBefore]);

    return (
        <div className={displayedText === '' ? "chat-window" : "chat-window2"}>
            <div className="chat-header">
                {selectedQuestion && (
                    <h2>
                        Query: <span style={{ fontSize: '20px' }}>{selectedQuestion}</span>
                    </h2>
                )}
            </div>
            {selectedChat && (
                <div className="chat-content">
                    <h4>Response</h4>
                    <p>{displayedText}</p>
                </div>
            )}
        </div>
    );
};

export default ChatWindow;
