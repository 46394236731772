import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../pages/Login/Login.scss';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSend = async () => {
    try {
      const response = await fetch('http://178.63.87.39:8008/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        navigate('/otp-verification');
      } else {
        console.log('Error sending email:', response.statusText);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div className="login-container">  {/* Reuse the class */}
      <h1>Acme Inc.</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="login">  {/* Reuse the class */}
          <label htmlFor="email">Enter your email address:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder='email@example.com'
          />
          <button onClick={handleSend}>Send</button>
        </div>
        <div className="divider" />
        <p><a href="/login">Back to Sign In</a></p>
      </form>
    </div>
  );
}

export default ForgotPassword;
