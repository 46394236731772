import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.scss';
import { callApi } from '../../utils/api';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(''); // For tracking login errors
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { username, password };

    try {
      const data = await callApi('/login', 'POST', payload);
      const accessToken = data.access_token;

      if (accessToken) {
        // Save token and login time to localStorage
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('login_time', Date.now()); // Save the current time as login time

        setLoginError(''); // Clear error on successful login
        navigate('/landing/landing/home'); // Redirect to the landing page
      } else {
        setLoginError('Username or password is incorrect'); // Set error message if login fails
      }
    } catch (error) {
      setLoginError('Username or password is incorrect'); // Set error message in case of API error
    }
  };

  return (
    <div className="login-container">
      <div className='arti'>
        <h1>Articence Inc.</h1>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="login">
            <label htmlFor="email">Username:</label>
            <input
              type="text"
              id="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              placeholder="Username"
              className="input-login"
            />
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
              className="input-login"
            />
            {loginError && (
              <p className="error-message">{loginError}</p> // Display error message if login fails
            )}
            <div className="checkbox">
              <input type="checkbox" id="remember-me" className="input-login" />
              <label htmlFor="remember-me">Remember me</label>
            </div>
            <button type="submit">Sign in</button>
          </div>
          <div className="divider" />
          <p>New around here? <a href="/signup">Sign up</a></p>
          <p><a href="/forgot-password">Forgot password?</a></p>
        </form>
      </div>
    </div>
  );
}

export default Login;
